var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.RESELLER')} (*)`,"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"reseller":_vm.organization.reseller.id,"filterable":true,"showAll":false},on:{"resellerChanged":(resellerId) => {
          _vm.organization.reseller.id = resellerId;
          _vm.organization.owner.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}}),_c('image-selector',{attrs:{"defaultImage":_vm.organization.logo,"ressource_name":"organizations","ressource_id":_vm.organization.id ? _vm.organization.id : 0,"field":"logo"},on:{"imageChanged":(file_url) => {
        _vm.organization.logo = file_url;
        _vm.onFormChanged();
      }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.name),callback:function ($$v) {_vm.$set(_vm.organization, "name", $$v)},expression:"organization.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}}),_c('base-input',{attrs:{"type":"textarea","label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.excerpt),callback:function ($$v) {_vm.$set(_vm.organization, "excerpt", $$v)},expression:"organization.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EMAIL')} (*)`,"placeholder":_vm.$t('COMMON.EMAIL')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.email),callback:function ($$v) {_vm.$set(_vm.organization, "email", $$v)},expression:"organization.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TYPE')}`}},[_c('el-select',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')},on:{"change":(type) => {
              _vm.organization.phone_type = type;
              _vm.onFormChanged();
            }},model:{value:(_vm.organization.phone_type),callback:function ($$v) {_vm.$set(_vm.organization, "phone_type", $$v)},expression:"organization.phone_type"}},_vm._l((_vm.phoneTypesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":value,"label":_vm.$t(`COMMON.PHONE_TYPE_${value}`)}})}),1)],1)],1),_c('div',{staticClass:"col-7"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PHONE')} (*)`}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.organization.phone,"required":true},on:{"phoneNumberChanged":(phone) => {
              _vm.organization.phone = phone;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}})],1),_c('div',{staticClass:"col-3"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.EXTENSION')}`,"inputClasses":'extension-input'},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.organization.phone_extension),callback:function ($$v) {_vm.$set(_vm.organization, "phone_extension", $$v)},expression:"organization.phone_extension"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone_extension}})],1)]),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.OTHER_PHONES')}`}},[_c('phone-numbers-selector',{attrs:{"phoneNumbers":_vm.organization.other_phones},on:{"phoneNumbersChanged":(phoneNumbers) => {
          _vm.organization.other_phones = phoneNumbers;
          _vm.onFormChanged();
        }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.other_phones}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ADDRESS')} (*)`,"placeholder":_vm.$t('COMMON.ADDRESS')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.organization.address),callback:function ($$v) {_vm.$set(_vm.organization, "address", $$v)},expression:"organization.address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}}),_c('h2',[_vm._v(_vm._s(_vm.$t("COMMON.BILLING_ADDRESS")))]),_c('billing-informations-form',{attrs:{"billingInformationsData":_vm.organization,"formErrors":_vm.formErrors},on:{"onChangeBillingInformations":_vm.billingInformationsChanged}}),_c('div',{staticClass:"my-4"},[_c('div',{staticClass:"row flex justify-content-between"},[_c('div',{staticClass:"col-auto"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.organization.id ? _vm.$t("ORGANIZATIONS.EDIT_ORGANIZATION") : _vm.$t("ORGANIZATIONS.ADD_ORGANIZATION"))+" ")])],1),_c('div',{staticClass:"col-auto"},[(_vm.showCancelButton)?_c('base-button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.closeOrganizationFormModal}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")]):_vm._e()],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }